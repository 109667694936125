<template>
	<div class="padding">
		<div class="flex justify-end">
			<q-btn
				v-if="can_merge_fields && fields.length"
				flat
				round
				size="md"
				icon="o_title"
				color="primary"
				@click="displayFieldsList()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add merge field
				</q-tooltip>
			</q-btn>
			<q-btn
				v-if="(!is_email_subject_line && !is_global_message)"
				flat
				round
				size="md"
				icon="o_content_copy"
				color="primary"
				@click="copyText()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Copy text
				</q-tooltip>
			</q-btn>
			<q-btn
				v-if="(!is_email_subject_line && !is_global_message)"
				flat
				round
				size="md"
				icon="o_content_paste"
				color="primary"
				@click="pasteText('scenario-message-text')"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Paste text
				</q-tooltip>
			</q-btn>
		</div>
		<q-select
			v-if="display_fields_list"
			label="Select Field"
			:options="fields"
			v-model="field_value"
			placeholder="Select Field"
			:option-label="(item) => item.label"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MessageContentAttachments from '@/components/message_forms/content_tab/MessageContentAttachments.vue';
import utils from "@/services/utils.js"

export default {
	name: 'CopyPasteAndMergeFields',
	components:{
		MessageContentAttachments,
	},
	props:{
		is_email_subject_line: Boolean,
		index: Number,
		device: Object,
		type: String,
		message_text: String,
		is_global_message: Boolean,
	},
	data() {
		return {
			field_value: {},
			display_fields_list: false,
			fields: [],
			can_merge_fields: false
		}
	},
	computed:{
		...mapGetters([
			'logged_user',
			'current_CG',
			'copied_text'
		]),
	},
	methods:{
		...mapActions([
			'updateGlobalMessage',
			'updateEmailSubjectLine',
			'updateDispatchContentValue',
			'updateMessageTextList',
			'updateAnsweringMachineMessageText',
		]),
		copyText(){
			if (this.type == 'message_separate_content' && this.index != null){
				utils.copyText(`#scenario_message_${this.index}`);
			}else if (this.type == 'answering_service'){
				utils.copyText('#answering_services_content');
			}else if (this.type == 'message_same_content'){
				utils.copyText('#scenario-message-text');
			}
		},
		pasteText(){
			if (this.type == 'message_separate_content' && this.index != null){
				let destination = `scenario_message_${this.index}`
				let scenario_message_text = (document.getElementById(destination).value += " " + this.copied_text);
				this.updateDispatchContentValue({
					dispatch_type: this.device.dispatch_type,
					text_value: scenario_message_text,
				});
				this.updateMessageTextList({ index: this.index, text_value: scenario_message_text });
			}else if (this.type == 'answering_service'){
				utils.pasteText('answering_services_content', 'updateAnsweringMachineMessageText')
			}else {
				utils.pasteText('scenario-message-text', 'updateGlobalMessage');
			}
		},
		displayFieldsList() {
			this.display_fields_list = !this.display_fields_list;
		}
	},
	created () {
		for (let field of this.logged_user.descendant_fields) {
			if(['MULTISELECT', 'SINGLESELECT', 'NUMERIC', 'TEXT', 'POSTCODE'].includes(field.field_type) && field.merge_field_enabled){
				this.fields.push(field);
			}
		}
		this.can_merge_fields = this.current_CG.root_customer_group_permissions.includes('Merge fields');
	},
	watch: {
		field_value: function(new_val) {
			let message = this.message_text + " #" + new_val.label + "#";
			if(this.type == 'message_separate_content' && this.index != null){
				this.updateDispatchContentValue({
					dispatch_type: this.device.dispatch_type,
					text_value: message,
				});
				this.updateMessageTextList({ index: this.index, text_value: message });
			}else if(this.type == 'message_same_content'){
				if (this.is_email_subject_line){
					this.updateEmailSubjectLine(message)				
				}else{
					this.updateGlobalMessage(message)
				}
			}else if (this.type == 'answering_service'){
				this.updateAnsweringMachineMessageText(message);
			}
		}
	}
}
</script>