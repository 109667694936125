<template>
	<div v-show="scenario_type == 'broadcast' || scenario_type == 'sequence'">
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Ask for a response:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="polling_value"
				/>
			</q-item-section>
		</q-item>

		<div v-if="polling_value">
			<div v-for="(polling_option, index) in polling_options" :key="index">
				<div>
					<PollingOption
						:index="index"
						:polling_option="polling_option"
					/>
				</div>
			</div>
		</div>
		<div class="row q-ma-md" v-if="polling_value">
			<q-btn
				outline
				rounded
				color="primary"
				size="md"
				icon="add"
				label="Add response option"
				@click="addNewPollingOption()"
				:disable="disabled_digit_option"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Max 10 response options
				</q-tooltip>
			</q-btn>
		</div>
		<PollingOptionSettings/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PollingOption from '@/components/message_forms/content_tab/PollingOption.vue';
import PollingOptionSettings from '@/components/message_forms/content_tab/PollingOptionSettings.vue';

export default {
	name: 'PollingOptionList',
	components:{
		PollingOption,
		PollingOptionSettings
	},
	data(){
		return{
			polling_disabled_warning: "",
			polling_disabled: false,
		}
	},
	computed:{
		...mapGetters([
			'scenario_type',
			'disabled_digit_option',
			'polling_options',
		]),
		polling_value:{
			get(){
				return this.$store.getters.polling_value;
			},
			set(val){
				if(!val){
					this.resetPollingOptionValues();
					this.updateDisabledDigitOption(false);
					this.resetSelectedDigits();
					this.updateSMSTwoWayLink(false);
				}
				this.updatePollingValue(val);
			}
		},
	},
	methods:{
		...mapActions([
			'updatePollingValue',
			'resetPollingOptionValues',
			'updatePollingOption',
			'updateDisabledDigitOption',
			'resetSelectedDigits',
			'updateSMSTwoWayLink',
		]),
		addNewPollingOption(){
			if(this.polling_options.length == 9){
				this.updateDisabledDigitOption(true);
			}
			this.updatePollingOption();
		}
	}
}
</script>