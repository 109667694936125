<template>
	<div class="padding q-pb-md">
		<div class="flex" v-if="!is_local_device && media_library_feature" >
			<q-btn
				icon="content_paste"
				@click="display_media_library_modal = true"
				color="dark"
				flat
				label="Add attachment from media library"
			/>
			<q-dialog v-model="display_media_library_modal">
				<MediaLibraryModal
					@close="display_media_library_modal = false"
					@choose-media-file="chooseMediaFile"
				/>
			</q-dialog>
		</div>
		<div class="flex" v-if="is_local_device && !is_recording">
			<q-file ref="file" v-model="file" style="display:none;" @input="handleFileUpload"/>
			<q-btn icon="attach_file" @click="btnClicked" color="dark" flat label="Add attachment from local device"/>
		</div>
		<div class="flex" v-else-if="is_local_device && is_recording">
			<q-file ref="file" v-model="file" style="display:none;" @input="handleFileUpload"/>
			<q-btn icon="attach_file" @click="btnClicked" color="dark" flat label="Browse"/>
		</div>
	</div>
</template>

<script>
import MediaLibraryModal from '@/components/message_forms/audio_noticeboard_tab/MediaLibraryModal.vue';
import { mapActions } from 'vuex';

export default {
	name: 'UploadButtons',
	components: {
		MediaLibraryModal
	},
	props: {
		is_local_device: Boolean,
		media_library_feature: Boolean,
		attachment_type: String,
		is_recording: Boolean
	},
	data() {
		return {
			display_media_library_modal: false,
			file: [],
		}
	},
	methods:{
		...mapActions(['updateSelectedFile']),
		handleFileUpload(){
			this.$emit('handleFileUpload', this.file);
		},
		btnClicked(){
			this.$refs.file.pickFiles();
		},
		chooseMediaFile(attachment){
			this.display_media_library_modal = false;
			for (let file of attachment){
				this.updateSelectedFile({"attachments": this.$store.getters.attachments, "media_file": file});
				this.$emit('set-attachment', this.$store.getters.selected_file);
			}
		}
	},
}
</script>