<template>
	<div v-if="should_display">
		<q-item class="full-width">
			<q-input
				label="Microsoft Teams content"
				type="textarea"
				outlined
				class="full-width"
				v-model="message_content_teams"
				for="scenario-message-teams"
				placeholder='This content will be sent to the selected microsoft teams webhooks.'
			/>
		</q-item>
		<CopyPasteMicrosoftTeams
			class="padding"
			:message_text='message_content_teams'
		/>

		<MessageContentAttachments
			attachment_type="TEAMS"
			:display_attachment_sliders_teams="true"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessageContentAttachments from "@/components/message_forms/content_tab/MessageContentAttachments.vue";
import CopyPasteMicrosoftTeams from "@/components/message_forms/content_tab/CopyPasteMicrosoftTeams.vue";

export default {
	name: "SeparateMessageContent",
	components: {
	CopyPasteMicrosoftTeams,
	MessageContentAttachments
},
	data() {
		return {
			should_display: false,
		};
	},
	computed: {
		...mapGetters([ 'current_CG' ]),
		message_content_teams: {
			get() {
				return this.$store.getters.message_content_teams
			},
			set(value) {
				this.updateMessageContentTeams(value);
			}
		},
	},
	methods: {
		...mapActions(["updateMessageContentTeams"]),
	},
	created(){
		this.should_display =
			this.current_CG.root_customer_group_permissions.includes('Use Microsoft Teams') &&
			this.$store.getters.use_microsoft_teams
	}
};
</script>
