<template>
	<div>
		<q-item v-if="voice_channel_active" tag="label">
			<q-item-section>
				<q-item-label>Use your account’s default answering services content?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="use_default_answering_services_content"/>
			</q-item-section>
		</q-item>
		<q-item v-if="voice_channel_active" tag="label">
			<q-item-section>
				<q-item-label>Use your account’s default TTS voice?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="use_accounts_default_tts_voice"/>
			</q-item-section>
		</q-item>
		<div
			v-if="voice_channel_active && (!use_accounts_default_tts_voice || !use_default_answering_services_content)"
			class="q-mb-md"
		>
			<q-select
				class="padding"
				v-model="tts_voice_language"
				:options="people"
				label="Choose a TTS voice to use for this message:"
				hint='Select a person in the list'
				:option-label="(item) => item.value"
			/>
		</div>

		<div v-if="voice_channel_active && !use_default_answering_services_content">
			<q-input
				class="padding"
				label="Answering services content"
				type="textarea"
				outlined
				v-model="message_text"
				for="answering_services_content"
				placeholder='This content will be played when we detect the call has been answered by an answering service (voicemail etc).'
			/>
				<CopyPasteAndMergeFields
					:type="type"
					:index='null'
					:message_text="message_text"
					:is_email_subject_line='false'
				/>
		</div>

		<div v-for="(device, index) in devices_message_content" :key="index">
			<SeparateMessageContent
				:index="index"
				:device="device"
			/>
		</div>
		<SeparateMessageContentTeams />
		<SeparateMessageContentSlack />
	</div>
</template>

<script>
import message_forms_utils from '@/utils/message_forms.js';
import { mapActions, mapGetters } from 'vuex';
import SeparateMessageContent from '@/components/message_forms/content_tab/SeparateMessageContent.vue';
import SeparateMessageContentTeams from '@/components/message_forms/content_tab/SeparateMessageContentTeams.vue';
import SeparateMessageContentSlack from '@/components/message_forms/content_tab/SeparateMessageContentSlack.vue';
import utils from "@/services/utils.js"
import CopyPasteAndMergeFields from "@/components/message_forms/content_tab/CopyPasteAndMergeFields.vue";

export default {
	name: 'DifferentMessageContentForAllDevices',
	components:{
		SeparateMessageContent,
		CopyPasteAndMergeFields,
		SeparateMessageContentTeams,
		SeparateMessageContentSlack,
	},
	data(){
		return{
			media_library_feature: false,
			error_message_main_voice: "",
			error_message_amd: "",
			display_fields_list: false,
			field_value: {},
			fields: [],
			type: "answering_service"
		}
	},
	computed:{
		...mapGetters([
			'voice_channel_active',
			'current_CG',
			'devices_message_content',
			'people',
			'logged_user'
		]),
		message_text:{
			get(){
				return this.$store.getters.message_text;
			},
			set(val){
				this.updateAnsweringMachineMessageText(val);
			}
		},
		tts_voice_language:{
			get(){
				return this.$store.getters.tts_voice;
			},
			set(val){
				this.updateTTSVoiceSelected(val);
			}
		},
		use_default_answering_services_content:{
			get(){
				return this.$store.getters['use_default_answering_services_content'];
			},
			set(val){
				this.updateUseDefaultAnsweringServicesContent(val);
				if (val){
					this.updateAnsweringMachineMessageText("");
				}
			}
		},
		use_accounts_default_tts_voice:{
			get(){
				return this.$store.getters['use_accounts_default_tts_voice'];
			},
			set(val){
				this.updateUseAccountsDefaultTTSVoice(val);
				if (!val){
					this.updateTTSVoiceSelected({});
				}
			}
		},
	},
	methods:{
		...mapActions([
			'updateAnsweringMachineMessageText',
			'updateTTSVoiceSelected',
			'updateUseDefaultAnsweringServicesContent',
			'updateUseAccountsDefaultTTSVoice'
		]),
		copyText(){
			utils.copyText('#answering_services_content');
		},
		pasteText(destination){
			utils.pasteText(destination, 'updateAnsweringMachineMessageText');
		},
		displayFieldsList() {
			this.display_fields_list = true;
		}
	},
	created(){
		this.media_library_feature = this.current_CG.root_customer_group_permissions.includes("Media library");
		let customer_group_settings = {};
		if(this.current_CG.customer_group.settings || this.current_CG.customer_group.settings != undefined){
			customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
		}

		if(!this.$route.params.id && !Object.keys(this.$store.getters.tts_voice).length){
			if(customer_group_settings['default_tts_voice'] != "" && customer_group_settings['default_tts_voice'] != undefined && customer_group_settings['default_tts_voice']['language']){
				this.updateTTSVoiceSelected(customer_group_settings['default_tts_voice']);
			}
			else{
				this.updateTTSVoiceSelected(message_forms_utils.default_people()[1]);
			}
		}

		for (let field of this.logged_user.descendant_fields) {
			if(['MULTISELECT', 'SINGLESELECT', 'NUMERIC', 'TEXT', 'POSTCODE'].includes(field.field_type)){
				this.fields.push(field);
			}
		}
	}
}
</script>
