<template>
	<div v-if="voice_channel_active">
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Re-call voice recipients if there is no response:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="retry_value"
				/>
			</q-item-section>
		</q-item>
		<q-input
			v-if="retry_value && scenario_type != 'conference'"
			class="padding"
			type="number"
			label="How many minutes interval should there be between each try?"
			v-model="auto_retry_minutes"
			hint="The value must be in range between 2 and 9 minutes."
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'RecallParticipants',
	computed:{
		...mapGetters(['voice_channel_active', 'scenario_type']),
		retry_value: {
			get(){
				if (this.scenario_type == 'conference'){
					return this.$store.getters.use_conference_recall;
				}else{
					return this.$store.getters.auto_retry;
				}
			},
			set(val){
				if (this.scenario_type == 'conference'){
					this.$store.dispatch('updateUseConferenceRecall', val);
				}else{
					if (!val){
						this.$store.dispatch('updateAutoRetryMinutes', 2);
					}
					this.$store.dispatch('updateAutoRetry', val);
				}
			}
		},
		auto_retry_minutes:{
			get(){
				return this.$store.getters.auto_retry_minutes;
			},
			set(val){
				this.$store.dispatch('updateAutoRetryMinutes', val);
			}
		},
	},
}
</script>
