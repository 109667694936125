<template>
	<div>
		<q-select
			v-model="conference_tts_voice_language"
			:options="people"
			label="Choose voice for your voice message"
			hint='Select a person in the list'
			:option-label="(item) => item.value"
			class="padding"
		/>
		<q-item class="q-m" tag="label">
			<q-item-section>
				<q-item-label>Use default conference bridge intro?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-radio v-model="conference_bridge_intro" :val="true" color="primary" />
			</q-item-section>
		</q-item>
		<q-item tag="label">
			<q-item-section>
				<q-item-label>Use our Text to speech to create your Conference Bridge Intro?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-radio v-model="conference_bridge_intro" :val="false" color="primary" />
			</q-item-section>
		</q-item>
		<q-input
			v-if="!conference_bridge_intro"
			outlined
			v-model="global_message"
			type="textarea"
			label="Message content"
			class="padding"
		/>
		<q-item class="q-m" tag="label">
			<q-item-section>
				<q-item-label>Use default conference bridge answering machine content?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-radio v-model="amd_conference" :val="true" color="primary" />
			</q-item-section>
		</q-item>
		<q-item tag="label">
			<q-item-section>
				<q-item-label>Use our Text to speech to create your Conference Bridge answering machine content?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-radio v-model="amd_conference" :val="false" color="primary" />
			</q-item-section>
		</q-item>
		<q-input
			v-if="!amd_conference"
			outlined
			v-model="message_text"
			type="textarea"
			label="Message content"
			class="padding"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import message_forms_utils from '@/utils/message_forms.js';

export default {
	name: 'ConferenceMessageContent',
	data() {
		return {
			is_default_message_live: "",
			is_default_message_amd: ""
		}
	},
	computed: {
		...mapGetters(['people', 'amd_conference', 'current_CG']),
		conference_tts_voice_language: {
			get(){
				return this.$store.getters.tts_voice;
			},
			set(val){
				this.updateTTSVoiceSelected(val);
			}
		},
		conference_bridge_intro: {
			get(){
				return this.$store.getters['conference_bridge_intro'];
			},
			set(val){
				this.updateCongerenceBridgeIntro(val);
			}
		},
		amd_conference: {
			get(){
				return this.$store.getters['amd_conference'];
			},
			set(val){
				this.updateAMDConference(val);
			}
		},
		global_message:{
			get(){
				return this.$store.getters.global_message;
			},
			set(val){
				this.updateGlobalMessage(val);
			}
		},
		message_text:{
			get(){
				return this.$store.getters.message_text;
			},
			set(val){
				this.updateAnsweringMachineMessageText(val);
			}
		},
	},
	methods: {
		...mapActions([
			'updateGlobalMessage',
			'updateAnsweringMachineMessageText',
			'updateTTSVoiceSelected',
			'updateCongerenceBridgeIntro',
			'updateAMDConference'
		]),
	},
	created(){
		let customer_group_settings = {};
		if(this.current_CG.customer_group.settings || this.current_CG.customer_group.settings != undefined){
			customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
		}

		try{
			this.is_default_message_live = !!customer_group_settings.conference.default_message_live;
			this.is_default_message_amd = !!customer_group_settings.conference.default_message_amd;
		}catch{}
		if(!this.$route.params.id && !Object.keys(this.$store.getters.tts_voice).length){
			if(customer_group_settings['default_tts_voice'] != "" && customer_group_settings['default_tts_voice'] != undefined && customer_group_settings['default_tts_voice']['language']){
				this.updateTTSVoiceSelected(this.customer_group_settings['default_tts_voice']);
			}
			else{
				this.updateTTSVoiceSelected(message_forms_utils.default_people()[1]);
			}
		}
	}
}
</script>