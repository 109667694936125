<template>
	<div v-if="show_content" class="router-view-wrapper">
		<div class="data-wrapper">
			<MessageContent v-if="scenario_type == 'broadcast' || scenario_type == 'sequence'" />
			<ConferenceMessageContent v-if="scenario_type == 'conference'" />
			<RecallParticipants />
			<PollingOptionList />
		</div>
	</div>
</template>

<script>
import MessageContent from "@/components/message_forms/content_tab/MessageContent.vue";
import ConferenceMessageContent from "@/components/message_forms/content_tab/ConferenceMessageContent.vue";
import PollingOptionList from "@/components/message_forms/content_tab/PollingOptionList.vue";
import RecallParticipants from '@/components/message_forms/content_tab/RecallParticipants.vue';
import { mapGetters } from "vuex";

export default {
	name: "MessageFormContent",
	components: {
		MessageContent,
		ConferenceMessageContent,
		PollingOptionList,
		RecallParticipants
	},
	data() {
		return {
			show_content: false
		};
	},
	computed: {
		...mapGetters(["scenario_type", "logged_user", "current_CG"])
	},
	methods: {
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Content'];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
	},
	created() {
		this.show_content =
			this.logged_user.customer_permission != "SENDER" ||
			(this.logged_user.customer_permission == "SENDER" &&
				this.current_CG.root_customer_group_permissions.includes(
					"Allow Senders to edit content"
				));
		this.getMessage()
	}
};
</script>