<template>
	<div v-if="should_display">
		<div class="message-content">
			<q-input
				class="padding"
				:label="device.title"
				type="textarea"
				outlined
				v-model="message_text"
				:placeholder='device.placeholder'
				:for="`scenario_message_${this.index}`"
			/>
			<CopyPasteAndMergeFields
				:type='type'
				:index='index'
				:device='device'
				:message_text='message_text'
				:is_email_subject_line='false'
			/>
			<MessageContentAttachments
				v-if="device.dispatch_type === 'EMAIL'"
				:attachment_type="device.dispatch_type"
				:index="index"
				:display_attachment_sliders_email="device.dispatch_type === 'EMAIL'"
				:display_attachment_sliders_sms="device.dispatch_type === 'SMS'"
			/>

			<MessageContentAttachments
				v-else-if="device.dispatch_type === 'SMS'"
				:attachment_type="device.dispatch_type"
				:index="index"
				:display_attachment_sliders_email="device.dispatch_type === 'EMAIL'"
				:display_attachment_sliders_sms="device.dispatch_type === 'SMS'"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessageContentAttachments from "@/components/message_forms/content_tab/MessageContentAttachments.vue";
import CopyPasteAndMergeFields from "@/components/message_forms/content_tab/CopyPasteAndMergeFields.vue";

export default {
	name: "SeparateMessageContent",
	components: {
		MessageContentAttachments,
		CopyPasteAndMergeFields,
	},
	props: {
		device: Object,
		index: Number,
	},
	data() {
		return {
			display_fields_list: false,
			fields: [],
			field_value: {},
			type: 'message_separate_content',
		};
	},
	computed: {
		...mapGetters([
			"voice_channel_active",
			"sms_channel_active",
			"email_channel_active",
		]),
		message_text: {
			get() {
				return this.device["text_value"];
			},
			set(val) {
				this.updateDispatchContentValue({
					dispatch_type: this.device.dispatch_type,
					text_value: val,
				});
				this.updateMessageTextList({ index: this.index, text_value: val });
			},
		},
		should_display() {
			if (this.device && this.device.dispatch_type === "VOICE") {
				if (this.device.is_answering) {
					return (
						this.voice_channel_active
					);
				} else {
					return this.voice_channel_active
				}
			} else if (this.device && this.device.dispatch_type === "SMS") {
				return this.sms_channel_active;
			} else if (this.device && this.device.dispatch_type === "EMAIL") {
				return this.email_channel_active;
			}
		},
	},
	methods: {
		...mapActions(["updateMessageTextList", "updateDispatchContentValue"]),
	},
};
</script>