<template>
	<div>
		<div v-if="is_email_selected">
			<q-input
				class="padding q-mt-md"
				v-model="email_subject_line"
				outlined
				label="Email subject line"
				name="email_subject_line"
				hint="Tell us what subject line to use for your email. You can use up to 50 characters"
			/>
			<CopyPasteAndMergeFields
				:type='type'
				:index='null'
				:is_email_subject_line='true'
				:message_text='email_subject_line'
			/>
		</div>
		<q-item tag="label">
			<q-item-section>
				<q-item-label class="text-left">Use same message content for all devices:</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="messages_content"
				/>
			</q-item-section>
		</q-item>

		<div v-if="messages_content && (scenario_type == 'broadcast' || scenario_type == 'sequence')">
			<!-- global text textarea -->
			<q-input
				class="padding"
				type="textarea"
				outlined
				v-model="global_message"
				for="scenario-message-text"
				label="Message content"
			/>
			<CopyPasteAndMergeFields
				:type='type'
				:index='null'
				:is_email_subject_line='false'
				:message_text='global_message'
				:is_global_message="true"
			/>
			<MessageContentAttachments
				:display_attachment_sliders_email='true'
				:display_attachment_sliders_sms='true'
				:display_attachment_sliders_teams="true"
				:display_attachment_sliders_slack="true"
			/>
		</div>
		<div v-if="!messages_content">
			<DifferentMessageContentForAllDevices/>
			<MessageContentAttachments
				:display_attachment_sliders_email='true'
				:display_attachment_sliders_sms='true'
				:display_attachment_sliders_teams="true"
				:display_attachment_sliders_slack="true"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MessageContentAttachments from '@/components/message_forms/content_tab/MessageContentAttachments.vue';
import DifferentMessageContentForAllDevices from '@/components/message_forms/content_tab/DifferentMessageContentForAllDevices.vue';
import CopyPasteAndMergeFields from "@/components/message_forms/content_tab/CopyPasteAndMergeFields.vue";

export default {
	name: 'MessageContent',
	components:{
		MessageContentAttachments,
		DifferentMessageContentForAllDevices,
		CopyPasteAndMergeFields,
	},
	data() {
		return {
			field_value: {},
			display_fields_list: false,
			fields: [],
			can_merge_fields: false,
			type: "message_same_content"
		}
	},
	computed:{
		...mapGetters([
			'scenario_type',
			'email_sms_attachment_files',
			'include_links_in_sms',
			'include_links_in_email',
			'sms_attachment_files',
			'email_attachment_files',
			'is_email_selected',
		]),
		email_subject_line:{
			get(){
				return this.$store.getters.email_subject_line;
			},
			set(val){
				this.updateEmailSubjectLine(val);
			}
		},
		messages_content:{
			get(){
				return this.$store.getters.messages_content;
			},
			set(value){
				if(!value){
					if(this.include_links_in_sms){
						let email_sms = JSON.parse(JSON.stringify(this.email_sms_attachment_files));
						this.$store.commit('setSMSAttachmentFiles', email_sms)
					}
					if(this.include_links_in_email){
						let email_sms = JSON.parse(JSON.stringify(this.email_sms_attachment_files));
						this.$store.commit('setEmailAttachmentFiles', email_sms)
					}
					this.$store.commit('setEmailSMSAttachmentFiles', []);
				}else{
					let attachments = [];
					if(this.sms_attachment_files && this.sms_attachment_files.length){
						attachments = this.sms_attachment_files;
					}
					if(this.email_attachment_files && this.email_attachment_files.length){
						attachments = attachments.concat(this.email_attachment_files);
					}

					for(let attachment of attachments){
						if(attachment.name){
							this.updateEmailSMSAttachmentFiles(
								{
									'source': attachment.source,
									'attachments': {'attachment_name': attachment['name'], 'attachment_key': attachment['key'], 'attachment_source': attachment.source, "id": attachment['id'], "customer_group": attachment['customer_group']}
								}
							);
						}else if(attachment.attachment_name){
							if(attachment.attachment_source === 'media_library') {
								this.updateEmailSMSAttachmentFiles(
									{
										'source': attachment.attachment_source,
										'attachments': { "name": attachment.attachment_name, "key": attachment.attachment_key, "source": attachment.attachment_source, "id": attachment['id'], "customer_group": attachment['customer_group'] }
									}
								)
							} else {
								this.updateEmailSMSAttachmentFiles(
									{
										'source': attachment.attachment_source,
										'attachments': attachment
									}
								)
							}
						}
					}

					// remove duplicate attachments
					const seen = new Set()
					const filteredArr = this.email_sms_attachment_files.filter(el => {
						const duplicate = seen.has(el.attachment_key);
						seen.add(el.attachment_key);
						return !duplicate
					})

					this.$store.commit('setEmailSMSAttachmentFiles', JSON.parse(JSON.stringify(filteredArr)));
					this.$store.commit('setSMSAttachmentFiles', []);
					this.$store.commit('setEmailAttachmentFiles', []);
				}
				this.updateGlobalMessage("");
				this.$store.dispatch('updateMessagesContent', value);
			}
		},
		global_message:{
			get(){
				return this.$store.getters.global_message;
			},
			set(val){
				this.updateGlobalMessage(val);
			}
		}
	},
	methods:{
		...mapActions([
			'updateGlobalMessage',
			'updateEmailSMSAttachmentFiles',
			'updateEmailSubjectLine',
		]),
	}
}
</script>
