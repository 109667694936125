<template>
	<div class="flex justify-end">
		<q-btn
			flat
			round
			size="md"
			icon="o_content_copy"
			color="primary"
			@click="copyText()"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Copy text
			</q-tooltip>
		</q-btn>
		<q-btn
			flat
			round
			size="md"
			icon="o_content_paste"
			color="primary"
			@click="pasteText('scenario-message-text')"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Paste text
			</q-tooltip>
		</q-btn>
	</div>
</template>

<script>
import utils from "@/services/utils.js"

export default {
	name: 'CopyPasteMicrosoftSlack',

	methods:{
		copyText(){
            utils.copyText('#scenario-message-slack');
		},
		pasteText(){
            utils.pasteText('scenario-message-slack', 'updateMessageContentSlack')
		},
	},
}
</script>
