<template>
	<div class="q-mb-md">
		<p v-if="warnings">{{ warnings }}</p>
		<div v-if="attachments.length > 0">
			<div class="padding"><strong>Attachments</strong></div>
			<div
				class="title-element row items-center element"
				v-for="(attachment, index) in attachments"
				:key="attachment.key"
			>
				<div v-if="attachment.attachment_name">
					{{ attachment.attachment_name }}
				</div>
				<div v-else-if="attachment.name">
					{{ attachment.name }}
				</div>
				<q-btn
					flat
					round
					color="primary"
					@click="remove_attachment(index)"
					icon="close"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DisplayAttachments',
	props: {
		attachments: Array,
		warnings: String
	},
	methods: {
		remove_attachment(index){
			this.$emit('remove-attachment', index);
		}
	}

}
</script>
<style lang="scss" scoped>
.title-element {
	padding: 0.5rem;
	padding-left: 1rem;
	border-color: $grey-8;
	font-size: 1rem;
	justify-content: space-between;

	&:hover {
		background-color: $grey-2;
	}
}
.element:hover {
	background-color: $grey-4;
}
</style>