<template>
	<div v-if="should_display">
		<q-item class="full-width">
			<q-input
				label="Microsoft Slack content"
				type="textarea"
				outlined
				class="full-width"
				v-model="message_content_slack"
				for="scenario-message-slack"
				placeholder='This content will be sent to the selected microsoft slack webhooks.'
			/>
		</q-item>
		<CopyPasteMicrosoftSlack
			class="padding"
			:message_text='message_content_slack'
		/>

		<MessageContentAttachments
			attachment_type="SLACK"
			:display_attachment_sliders_slack="true"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessageContentAttachments from "@/components/message_forms/content_tab/MessageContentAttachments.vue";
import CopyPasteMicrosoftSlack from "@/components/message_forms/content_tab/CopyPasteSlack.vue";


export default {
	name: "SeparateMessageContent",
	components: {
	CopyPasteMicrosoftSlack,
	MessageContentAttachments
},
	data() {
		return {
			should_display: false,
		};
	},
	computed: {
		...mapGetters([ 'current_CG' ]),
		message_content_slack: {
			get() {
				return this.$store.getters.message_content_slack
			},
			set(value) {
				this.updateMessageContentSlack(value);
			}
		},
	},
	methods: {
		...mapActions(["updateMessageContentSlack"]),
	},
	created(){
		this.should_display =
			this.current_CG.root_customer_group_permissions.includes('Use Slack') &&
			this.$store.getters.use_slack
	}
};
</script>
