<template>
	<div class="attachments">
		<div v-if="messages_content">
			<div v-for="settings in slider_settings" :key="settings.label">
				<q-item tag="label" v-if="settings.condition">
					<q-item-section>
						<q-item-label>{{settings.label}}</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							v-model="settings.model"
							@input="update(settings.model, settings.action)"
						/>
					</q-item-section>
				</q-item>
			</div>
			<UploadButtons
				v-if="messages_content && (include_links_in_email || include_links_in_sms || include_links_in_teams || include_links_in_slack)"
				:is_local_device="true"
				:action="'updateUploadRecordingEmail'"
				:media_library_feature="media_library_feature"
				@handleFileUpload="handleFileUpload($event, 'email_sms')"
				@set-attachment="setAttachment('email_sms')"
			/>
			<UploadButtons
				v-if="media_library_feature && (include_links_in_email || include_links_in_sms || include_links_in_teams || include_links_in_slack)"
				:is_local_device="false"
				:action="'updateUploadRecordingEmail'"
				:media_library_feature="media_library_feature"
				@handleFileUpload="handleFileUpload($event, 'email_sms')"
				@set-attachment="setAttachment('email_sms')"
			/>
			<DisplayAttachments
				:attachments="email_sms_attachment_files"
				:warnings="email_sms_attachment_warnings"
				@remove-attachment="removeAttachment($event, 'email_sms')"
			/>
		</div>
		<div v-else>
			<div v-if="attachment_type === 'SMS'">
				<q-item tag="label" v-if="media_library_feature && sms_channel && sms_channel_active && display_attachment_sliders_sms">
					<q-item-section>
						<q-item-label>Add file links to your SMS?</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							v-model="include_links_in_sms"
						/>
					</q-item-section>
				</q-item>
				<UploadButtons
					v-if="!messages_content && include_links_in_sms"
					:is_local_device="true"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<UploadButtons
					v-if="!messages_content && media_library_feature && (include_links_in_sms)"
					:is_local_device="false"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<DisplayAttachments
					:attachments="sms_attachment_files"
					:warnings="sms_attachment_warnings"
					@remove-attachment="removeAttachment($event, attachment_type)"
				/>
			</div>
			<div v-else-if="attachment_type === 'EMAIL'">
				<q-item tag="label" v-if="email_channel_active && display_attachment_sliders_email">
					<q-item-section>
						<q-item-label>Add file links to your Email?</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							v-model="include_links_in_email"
						/>
					</q-item-section>
				</q-item>

				<UploadButtons
					v-if="!messages_content && include_links_in_email"
					:is_local_device="true"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<UploadButtons
					v-if="!messages_content && media_library_feature && (include_links_in_email)"
					:is_local_device="false"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<DisplayAttachments
					:attachments="email_attachment_files"
					:warnings="email_attachment_warnings"
					@remove-attachment="removeAttachment($event, attachment_type)"
				/>
			</div>
			<div v-else-if="attachment_type === 'TEAMS'">
				<q-item tag="label" v-if="this.use_microsoft_teams && this.display_attachment_sliders_teams">
					<q-item-section>
						<q-item-label>Add file links to MS Teams message?</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							v-model="include_links_in_teams"
						/>
					</q-item-section>
				</q-item>

				<UploadButtons
					v-if="!messages_content && include_links_in_teams"
					:is_local_device="true"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<UploadButtons
					v-if="!messages_content && media_library_feature && (include_links_in_teams)"
					:is_local_device="false"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<DisplayAttachments
					:attachments="teams_attachment_files"
					:warnings="teams_attachment_warnings"
					@remove-attachment="removeAttachment($event, attachment_type)"
				/>
			</div>
			<div v-else-if="attachment_type === 'SLACK'">
				<q-item tag="label" v-if="this.use_slack && this.display_attachment_sliders_slack">
					<q-item-section>
						<q-item-label>Add file links to Slack message?</q-item-label>
					</q-item-section>
					<q-item-section avatar>
						<q-toggle
							v-model="include_links_in_slack"
						/>
					</q-item-section>
				</q-item>

				<UploadButtons
					v-if="!messages_content && include_links_in_slack"
					:is_local_device="true"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<UploadButtons
					v-if="!messages_content && media_library_feature && (include_links_in_slack)"
					:is_local_device="false"
					:media_library_feature="media_library_feature"
					:attachment_type="attachment_type"
					@handleFileUpload="handleFileUpload($event, attachment_type)"
					@set-attachment="setAttachment(attachment_type)"
				/>
				<DisplayAttachments
					:attachments="slack_attachment_files"
					:warnings="slack_attachment_warnings"
					@remove-attachment="removeAttachment($event, attachment_type)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MessageFormsAPI from '@/services/api/MessageForm.js';
import UploadButtons from '@/components/message_forms/content_tab/UploadButtons.vue';
import DisplayAttachments from '@/components/message_forms/content_tab/DisplayAttachments.vue';

export default {
	name: 'MessageContentAttachments',
	components:{
		UploadButtons,
		DisplayAttachments
	},
	props:{
		display_attachment_sliders_email: Boolean,
		display_attachment_sliders_sms: Boolean,
		display_attachment_sliders_teams: Boolean,
		display_attachment_sliders_slack: Boolean,
		attachment_type: String
	},
	data(){
		return{
			media_library_feature: false,
			sms_channel: false,
			file: {},
			email_attachment_file: {},
		}
	},
	computed:{
		...mapGetters([
			'current_CG',
			'sms_channel_active',
			'email_channel_active',
			'email_sms_attachment_files',
			'email_sms_attachments_size',
			'messages_content',
			'include_links_in_sms',
			'include_links_in_email',
			'include_links_in_teams',
			'include_links_in_slack',
			'upload_recording_email',
			'upload_email_attachment',
			'upload_sms_attachment',
			'email_attachment_files',
			'teams_attachment_files',
			'slack_attachment_files',
			'sms_attachment_files',
			'email_attachments_size',
			'teams_attachments_size',
			'slack_attachments_size',
			'sms_attachments_size',
			'email_sms_attachment_warnings',
			'sms_attachment_warnings',
			'email_attachment_warnings',
			'teams_attachment_warnings',
			'slack_attachment_warnings',
			'selected_file',
			'attachments',
			'use_microsoft_teams',
			'use_slack'
		]),
		slider_settings(){
			return [
				{
					"condition": this.media_library_feature && this.sms_channel && this.sms_channel_active && this.display_attachment_sliders_sms,
					"label": 'Add file links to your SMS?',
					"model": this.$store.getters['include_links_in_sms'],
					"action": "updateIncludeLinksInSMS"
				},
				{
					"condition": this.email_channel_active && this.display_attachment_sliders_email,
					"label": 'Add file links to your Email?',
					"model": this.$store.getters['include_links_in_email'],
					"action": "updateIncludeLinksInEmail"
				},
				{
					"condition": this.use_microsoft_teams && this.display_attachment_sliders_teams,
					"label": 'Add file links to your MS Teams message?',
					"model": this.$store.getters['include_links_in_teams'],
					"action": "updateIncludeLinksInTeams"
				},
				{
					"condition": this.use_slack && this.display_attachment_sliders_slack,
					"label": 'Add file links to your Slack message?',
					"model": this.$store.getters['include_links_in_slack'],
					"action": "updateIncludeLinksInSlack"
				},
			]
		},
		include_links_in_sms: {
			get(){
				return this.$store.getters['include_links_in_sms'];
			},
			set(val){
				this.updateIncludeLinksInSMS(val);
			}
		},
		include_links_in_email: {
			get(){
				return this.$store.getters['include_links_in_email'];
			},
			set(val){
				this.updateIncludeLinksInEmail(val);
			}
		},
		include_links_in_teams: {
			get(){
				return this.$store.getters['include_links_in_teams'];
			},
			set(val){
				this.updateIncludeLinksInTeams(val);
			}
		},
		include_links_in_slack: {
			get(){
				return this.$store.getters['include_links_in_slack'];
			},
			set(val){
				this.updateIncludeLinksInSlack(val);
			}
		},
	},
	methods:{
		...mapActions([
			'toggleMutuallyExclusiveSliders',
			'updateEmailSMSAttachmentFiles',
			'removeEmailSMSAttachment',
			'removeEmailSMSAttachmentSize',
			'updateEmailSMSAttachmentWarnings',
			'removeAllEmailSMSAttachments',
			'updateSMSAttachmentFiles',
			'updateEmailSMSAttachmentsSize',
			'updateEmailAttachmentsSize',
			'removeAllEmailAttachments',
			'updateEmailAttachmentFiles',
			'removeEmailAttachment',
			'updateEmailAttachmentWarnings',
			'removeEmailAttachmentSize',
			'removeSMSAttachment',
			'removeAllSMSAttachments',
			'updateSMSAttachmentsSize',
			'updateSMSAttachmentWarnings',
			'updateIncludeLinksInSMS',
			'updateIncludeLinksInEmail',
			'updateUploadRecordingEmail',
			'updateIncludeLinksInTeams',
			'updateIncludeLinksInSlack',
			'updateUploadSMSAttachment',
			'updateUploadEmailAttachment',
			'removeSMSAttachmentSize',
			'updateTeamsAttachmentFiles',
			'updateSlackAttachmentFiles',
			'removeTeamsAttachment',
			'removeSlackAttachment',
			'removeAllTeamsAttachments',
			'removeAllSlackAttachments',
			'updateTeamsAttachmentsSize',
			'updateSlackAttachmentsSize',
			'updateTeamsAttachmentWarnings',
			'updateSlackAttachmentWarnings',
			'removeTeamsAttachmentSize',
			'removeSlackAttachmentSize',
		]),
		setAttachment(attachment_type){
			if(this.selected_file){
				if(attachment_type === 'email_sms'){
					this.updateEmailSMSAttachmentFiles({'source': 'media_library', 'attachments': this.selected_file});
				}else if(attachment_type === 'EMAIL'){
					this.updateEmailAttachmentFiles({'source': 'media_library', 'attachments': this.selected_file});
				}else if(attachment_type === 'SMS'){
					this.updateSMSAttachmentFiles({'source': 'media_library', 'attachments': this.selected_file});
				}else if(attachment_type === 'TEAMS'){
					this.updateTeamsAttachmentFiles({'source': 'media_library', 'attachments': this.selected_file});
				}else if(attachment_type === 'SLACK'){
					this.updateSlackAttachmentFiles({'source': 'media_library', 'attachments': this.selected_file});
				}
			}
		},
		async handleFileUpload(file, attachment_type){
			if(file != undefined){
				if(this.messages_content){
					if(this.email_sms_attachments_size+file.size >= 10485760){
						this.updateEmailSMSAttachmentWarnings('Your attachments size was over 10MB. You are not permitted to send files with size bigger than 10MB.');
						this.email_sms_attachment_file = undefined;
					}
					else if(this.email_sms_attachments_size+file.size >= 5242880 && this.email_sms_attachments_size+file.size < 10485760){
						this.updateEmailSMSAttachmentWarnings('Your file is bigger than 5MB. Please be careful with sending big files as an email attachment.');
						this.email_sms_attachment_file = file;
					}
					else{
						this.updateEmailSMSAttachmentWarnings('');
						this.email_sms_attachment_file = file;
					}
					if(this.email_sms_attachment_file != null && (this.email_sms_attachments_size+this.email_sms_attachment_file.size) < 10485760){
						let form_data = new FormData();
						form_data.append("file", this.email_sms_attachment_file);
						let response = await MessageFormsAPI.uploadEmailAttachment(form_data);
						let attachment = JSON.parse(response);
						this.updateEmailSMSAttachmentFiles({'source': 'local_device', 'attachments': attachment});
						this.updateEmailSMSAttachmentsSize(attachment.attachment_size);
					}
				}else{
					let response = {};
					let attachment = {};
					if(attachment_type === 'EMAIL'){
						if(this.email_attachments_size+file.size >= 10485760){
							this.updateEmailAttachmentWarnings('Your attachments size was over 10MB. You are not permitted to send files with size bigger than 10MB.');
							this.email_attachment_file = undefined;
						}
						else if(this.email_attachments_size+file.size >= 5242880 && this.email_attachments_size+file.size < 10485760){
							this.updateEmailAttachmentWarnings('Your file is bigger than 5MB. Please be careful with sending big files as an email attachment.');
							this.email_attachment_file = file;
						}
						else{
							this.updateEmailAttachmentWarnings('');
							this.email_attachment_file = file;
						}
						if(this.email_attachment_file != null && (this.email_attachments_size+this.email_attachment_file.size) < 10485760){
							let form_data = new FormData();
							form_data.append("file", this.email_attachment_file);
							response = await MessageFormsAPI.uploadEmailAttachment(form_data);
							attachment = JSON.parse(response);
							this.updateEmailAttachmentFiles({'source': 'local_device', 'attachments': attachment});
							this.updateEmailAttachmentsSize(attachment.attachment_size);
						}
					}else if(attachment_type === 'SMS'){
						if(this.sms_attachments_size+file.size >= 10485760){
							this.updateSMSAttachmentWarnings('Your attachments size was over 10MB. You are not permitted to send files with size bigger than 10MB.');
							this.sms_attachment_file = undefined;
						}
						else if(this.sms_attachments_size+file.size >= 5242880 && this.sms_attachments_size+file.size < 10485760){
							this.updateSMSAttachmentWarnings('Your file is bigger than 5MB. Please be careful with sending big files as an email attachment.');
							this.sms_attachment_file = file;
						}
						else{
							this.updateSMSAttachmentWarnings('');
							this.sms_attachment_file = file;
						}
						if(this.sms_attachment_file != null && (this.sms_attachments_size+this.sms_attachment_file.size) < 10485760){
							let form_data = new FormData();
							form_data.append("file", this.sms_attachment_file);
							response = await MessageFormsAPI.uploadSMSAttachment(form_data);
							attachment = JSON.parse(response);
							let attachment_tmp = {
								"attachment_name": attachment.name,
								"attachment_key": attachment.key,
								"attachment_source": 'local_device',
								"attachment_size": attachment.size
							}
							this.updateSMSAttachmentFiles({'source': 'local_device', 'attachments': attachment_tmp});
							this.updateSMSAttachmentsSize(attachment_tmp.attachment_size);
						}
					}else if(attachment_type === 'TEAMS'){
						if(this.teams_attachments_size+file.size >= 10485760){
							this.updateTeamsAttachmentWarnings('Your attachments size was over 10MB. You are not permitted to send files with size bigger than 10MB.');
							this.teams_attachment_file = undefined;
						}
						else if(this.teams_attachments_size+file.size >= 5242880 && this.teams_attachments_size+file.size < 10485760){
							this.updateTeamsAttachmentWarnings('Your file is bigger than 5MB. Please be careful with sending big files as teams attachment.');
							this.teams_attachment_file = file;
						}
						else{
							this.updateTeamsAttachmentWarnings('');
							this.teams_attachment_file = file;
						}
						if(this.teams_attachment_file != null && (this.teams_attachments_size+this.teams_attachment_file.size) < 10485760){
							let form_data = new FormData();
							form_data.append("file", this.teams_attachment_file);
							form_data.append('provider_name', 'teams')
							response = await MessageFormsAPI.uploadProviderAttachment(form_data);
							attachment = JSON.parse(response);
							this.updateTeamsAttachmentFiles({'source': 'local_device', 'attachments': attachment});
							this.updateTeamsAttachmentsSize(attachment.attachment_size);
						}
					}else if(attachment_type === 'SLACK'){
						if(this.slack_attachments_size+file.size >= 10485760){
							this.updateSlackAttachmentWarnings('Your attachments size was over 10MB. You are not permitted to send files with size bigger than 10MB.');
							this.slack_attachment_file = undefined;
						}
						else if(this.slack_attachments_size+file.size >= 5242880 && this.slack_attachments_size+file.size < 10485760){
							this.updateSlackAttachmentWarnings('Your file is bigger than 5MB. Please be careful with sending big files as slack attachment.');
							this.slack_attachment_file = file;
						}
						else{
							this.updateSlackAttachmentWarnings('');
							this.slack_attachment_file = file;
						}
						if(this.slack_attachment_file != null && (this.slack_attachments_size+this.slack_attachment_file.size) < 10485760){
							let form_data = new FormData();
							form_data.append("file", this.slack_attachment_file);
							form_data.append('provider_name', 'slack')
							response = await MessageFormsAPI.uploadProviderAttachment(form_data);
							attachment = JSON.parse(response);
							this.updateSlackAttachmentFiles({'source': 'local_device', 'attachments': attachment});
							this.updateSlackAttachmentsSize(attachment.attachment_size);
						}
					}
				}
			}
		},
		removeAttachment(index, attachment_type){
			if(attachment_type === 'email_sms'){
				if(this.email_sms_attachment_files[index].attachment_source === 'local_device'){
					this.removeEmailSMSAttachmentSize(this.email_sms_attachment_files[index].attachment_size);
				}
				this.removeEmailSMSAttachment(index);

			}else if(attachment_type === 'EMAIL'){
				if(this.email_attachment_files[index].attachment_source === 'local_device'){
					this.removeEmailAttachmentSize(this.email_attachment_files[index].attachment_size);
				}
				this.removeEmailAttachment(index);
			}else if(attachment_type === 'SMS'){
				if(this.sms_attachment_files[index].attachment_source === 'local_device'){
					this.removeSMSAttachmentSize(this.sms_attachment_files[index].attachment_size);
				}
				this.removeSMSAttachment(index);
			}else if(attachment_type === 'TEAMS'){
				if(this.teams_attachment_files[index].attachment_source === 'local_device'){
					this.removeTeamsAttachmentSize(this.teams_attachment_files[index].attachment_size);
				}
				this.removeTeamsAttachment(index);
			}	else if(attachment_type === 'SLACK'){
				if(this.slack_attachment_files[index].attachment_source === 'local_device'){
					this.removeSlackAttachmentSize(this.slack_attachment_files[index].attachment_size);
				}
				this.removeSlackAttachment(index);
			}
		},
		update(settings_model, settings_action){
			this.$store.dispatch(settings_action, settings_model);
			if(!this.include_links_in_sms && !this.include_links_in_email && !this.include_links_in_teams && !this.include_links_in_slack){
				this.removeAllEmailSMSAttachments();
			}
			if(!this.include_links_in_sms && !this.messages_content){
				this.removeAllSMSAttachments();
			}
			if(!this.include_links_in_email && !this.messages_content){
				this.removeAllEmailAttachments();
			}
			if(!this.include_links_in_teams && !this.messages_content){
				this.removeAllTeamsAttachments();
			}
			if(!this.include_links_in_slack && !this.messages_content){
				this.removeAllSlackAttachments();
			}
		},
	},
	created(){
		this.media_library_feature = this.current_CG.root_customer_group_permissions.includes("Media library");
		this.sms_channel = this.current_CG.root_customer_group_permissions.includes("SMS");
	},

}
</script>
