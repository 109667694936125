<template>
	<div>
		<div class="q-mx-md">
			<q-select
				v-if="polling_value && over_ride_timeout"
				label="Default on time response duration"
				v-model="polling_timeout"
				:options="timeout_choices"
				color="primary"
				:option-label="(item) => item.text"
			/>
		</div>
		<div v-if="polling_value && over_ride_timeout" class="q-px-md">
			<details-button
				class="format"
				:tooltip_content="tooltip_content"
				name="polling-options-settings"
			/>
		</div>
		<q-item tag="label" v-if="display_sms_links_slider">
			<q-item-section>
				<q-item-label class="text-left">Use SMS links for SMS replies?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="sms_two_way_link"
				/>
			</q-item-section>
		</q-item>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'PollingOptionSettings',
	components:{
		DetailsButton
	},
	computed:{
		...mapGetters([
			'polling_value',
			'current_CG',
			'sms_channel_active',
		]),
		sms_two_way_link:{
			get(){
				return this.$store.getters.sms_two_way_link;
			},
			set(val){
				this.updateSMSTwoWayLink(val);
			}
		},
		polling_timeout:{
			get(){
				return this.$store.getters.polling_timeout;
			},
			set(val){
				this.updatePollingTimeout(val);
			}
		},
		polling_hard_timeout:{
			get(){
				return this.fancy_time_format(parseInt(this.polling_timeout.value) + 3600);
			}
		},
		tooltip_content(){
			if (this.polling_hard_timeout){
				return 'Late response duration: 1 hour \n' + 'Total response duration: ' + this.polling_hard_timeout
			}
		},
		display_sms_links_slider() {
			return this.two_way_sms_link_permission &&
				this.$store.getters.polling_value &&
				this.$store.getters.sms_channel_active &&
				!this.two_way_sms
		}
	},
	data(){
		return{
			two_way_sms_link_permission: false,
			over_ride_timeout: false,
			timeout_choices: [
				{
					'value':'900',
					'text': '15 minutes',
				},
				{
					'value':'1800',
					'text': '30 minutes',
				},
				{
					'value':'3600',
					'text':'1 hour',
				},
				{
					'value':'7200',
					'text':'2 hours',
				},
				{
					'value':'14400',
					'text':'4 hours',
				},
				{
					'value':'21600',
					'text':'6 hours',
				},
				{
					'value':'43200',
					'text':'12 hours',
				},
				{
					'value':'64800',
					'text':'18 hours',
				},
				{
					'value':'86400',
					'text':'24 hours',
				},
				{
					'value': '604800',
					'text': '7 days'
				}
			],
			two_way_sms: false
		}
	},
	methods:{
		...mapActions(['updatePollingTimeout', 'updateSMSTwoWayLink']),
		fancy_time_format(time){
			let hrs = ~~(time / 3600);
			let mins = ~~((time % 3600) / 60);

			// Output like "1:01" or "4:03:59" or "123:03:59"
			let ret = "";
			if (hrs == 1) {
				ret += "" + hrs + " hour " + (mins < 10 ? "0" : "");
			} else if (hrs >= 24){
				let days = null
				let hours = hrs
				days = Math.floor(hrs/24)
				hours = hrs % 24
				let days_str = days == 1 ? "" + days + " day " : "" + days + " days "
				let hours_str = hours == 1 ? "" + hours + " hour " : "" + hours + " hours "
				ret +=  days_str + hours_str
			}else if (hrs > 1) {
				ret += "" + hrs + " hours ";
			}
			if (mins > 0) {
				if (mins < 10){
					ret += "0"
				}
				ret += "" + mins + " minutes";
			}
			return ret;
		},
	},
	created(){
		if(Object.keys(this.polling_timeout).length === 0 && JSON.parse(this.current_CG.customer_group.settings)['default_message_timeout']){
			this.updatePollingTimeout(JSON.parse(this.current_CG.customer_group.settings)['default_message_timeout'])
		}
		this.two_way_sms_link_permission = this.current_CG.root_customer_group_permissions.includes("SMS links for 2 way messages");
		const settings = JSON.parse(this.current_CG.customer_group.settings);
		this.two_way_sms = settings.force_two_way_sms;

		this.over_ride_timeout = this.current_CG.root_customer_group_permissions.includes("Over-ride default on time response duration");
	},
}
</script>
<style>
.format{
	white-space: pre-wrap;
}
</style>
