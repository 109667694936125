<template>
	<q-item class="justify-between wrap">
		<q-select
			v-model="polling_number"
			:options="local_digits"
			label="Select digit"
			color="primary"
			@input="digitSelected"
			class="col-12"
			:class="polling_options.length > 1 ? 'col-md-5' : 'col-md-6'"
		/>
		<q-input
			v-if="polling_value"
			v-model="polling_option_text"
			label="Specify what this digit means"
			class="col-12"
			:class="polling_options.length > 1 ? 'col-md-5' : 'col-md-6'"
		/>
		<div
			:class="[
				$q.screen.gt.sm ? 'col-1 flex justify-end items-center' : 'flex justify-end full-width'
			]"
		>	
			<q-btn
				v-if="polling_options.length > 1"
				flat
				round
				color="primary"
				icon="close"
				@click="deletePollingOption"
			/>
		</div>
	</q-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: "PollingOption",
	props:{
		index: Number,
		option: Object,
	},
	data(){
		return{
			polling_numbers: [0,1,2,3,4,5,6,7,8,9],
			local_digits: []
		}
	},
	computed:{
		...mapGetters([
			'polling_value',
			'selected_digits',
			'polling_options'
		]),
		polling_number:{
			get(){
				return this.polling_options[this.index]['option_digit'];
			},
			set(val){
				this.updatePollingOption({"index": this.index, "val": val, "option_digit": true});
			}
		},
		polling_option_text:{
			get(){
				return this.polling_options[this.index]['option_text'];
			},
			set(val){
				this.updatePollingOption({"index": this.index, "val": val, "option_text": true});
			}
		}
	},
	methods:{
		...mapActions([
			'removePollingOption',
			'updatePollingOption',
			'updateSelectedDigitOption',
			'updateDisabledDigitOption'
		]),
		deletePollingOption(){
			this.selected_digits.splice(this.index, 1)
			this.removePollingOption(this.index);
			this.updateDisabledDigitOption(false);
		},
		digitSelected(){
			if(this.local_digits.length == 1){
				this.updateDisabledDigitOption(true);
			}
			this.updateSelectedDigitOption({"index": this.index, "label": this.polling_number});
		},
		fetchDigitOptions(){
			this.local_digits = [];
			for(let i in this.polling_numbers){
				if(!this.selected_digits.includes(this.polling_numbers[i])){
					this.local_digits.push(this.polling_numbers[i]);
				}
			}
		}
	},
	created(){
		this.fetchDigitOptions()
	}
}
</script>
<style lang="scss" scoped>
	.wrap{
		flex-wrap: wrap;
	}
</style>